<template>
  <b-card v-if="application" class="p-2">
    <!--More Info Modal -->
    <b-modal
        ref="more-info-modal"
        ok-only
        hide-footer
        modal-class="modal-primary"
        centered
        title="Request More Information"
        @hidden="moreInfoData=null"
    >
      <form @submit.prevent="sendMoreInfo">
        <b-form-textarea rows="8"
                         placeholder="Add any additional document or information required from the applicant"
                         class="mb-2" v-model="moreInfoData"
        >

        </b-form-textarea>

        <div class="d-flex justify-content-end">
          <b-button
              type="submit"
              variant="primary"
              :disabled="!moreInfoData"
          >
            Send
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- /More Info Modal -->

    <!-- Rejection Modal -->
    <b-modal
        ref="rejection-modal"
        ok-only
        hide-footer
        modal-class="modal-primary"
        centered
        title="Reject"
        @hidden="rejectionReasons=null"
    >
      <form @submit.prevent="reject">
        <b-form-textarea rows="8"
                         placeholder="Please add rejection reason(s)"
                         class="mb-2" v-model="rejectionReasons"
        >

        </b-form-textarea>

        <div class="d-flex justify-content-end">
          <b-button
              type="submit"
              variant="primary"
              :disabled="!rejectionReasons"
          >
            Save
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- /Rejection Modal -->

    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h4>
        {{ `${application.details.firstName} ${application.details.lastName}` }}

        <b-badge
            variant="info"
            class="badge-glow ml-1"
        >
          {{ application.statusInfo.description }}
        </b-badge>
      </h4>

      <div>
        <b-button
            v-if="allowedAction('Reject')"
            variant="danger"
            class="mr-2"
            @click="$refs['rejection-modal'].show()"
        >
          Final Rejection
        </b-button>
        <b-button
            v-if="allowedAction('Accept')"
            variant="success"
            @click="approve"
        >
          Final Approval
        </b-button>
      </div>
    </div>

    <hr>

    <div class="basic-details pt-2 mb-5">
      <b-row>
        <b-col md="6" xl="4" class="mb-4">
          <i class="fa-solid fa-briefcase mr-1"></i>
          <span>{{ application.details.profession.text }}</span>
        </b-col>

        <b-col md="6" xl="4" class="mb-4">
          <i class="fa-solid fa-envelope mr-1"></i>
          <span>{{ application.details.emailAddress }}</span>
        </b-col>

        <b-col md="6" xl="4" class="mb-4">
          <i class="fa-solid fa-phone mr-1"></i>
          <span>{{ application.details.cellPhone }}</span>
        </b-col>

        <b-col md="6" xl="4" class="mb-4">
          <i class="fa-solid fa-globe mr-1"></i>
          <span>{{ application.details.country ? application.details.country.name : 'Country Not added' }}</span>
        </b-col>

        <b-col md="6" xl="4" class="mb-4">
          <i class="fa-brands fa-linkedin mr-1"></i>
          <span>{{ application.details.linkedInUrl ? application.details.linkedInUrl : 'Not added' }}</span>
        </b-col>

        <b-col md="6" xl="4">
          <i class="fa-solid fa-link mr-1"></i>
          <span>{{ application.details.websiteUrl ? application.details.websiteUrl : 'Not added' }}</span>
        </b-col>
      </b-row>
      <hr>

      <div class="mb-5 pt-2">
        <h4 class="mb-2">Documents</h4>
        <b-row>
          <b-col md="6" xl="4">
            <div class="d-flex align-items-center border mr-lg-5 p-3">
              <i class="fa-regular fa-file fa-2x"></i>

              <div class="ml-2">
                <h6 class="mb-0">Resume</h6>
              </div>

              <div v-if="application.details.resumeUrl" class="ml-auto">
                <b-button
                    variant="flat-info"
                    class="btn-icon"
                    :href="application.details.resumeUrl"
                    target="_blank"
                >
                  <feather-icon icon="DownloadCloudIcon"/>
                </b-button>
              </div>
            </div>
          </b-col>

          <b-col md="6" xl="4">
            <div class="d-flex align-items-center border mr-lg-5 p-3">
              <i class="fa-regular fa-file fa-2x"></i>

              <div class="ml-2">
                <h6 class="mb-0">Portfolio</h6>
              </div>

              <div v-if="application.details.portfolioUrl" class="ml-auto">
                <b-button
                    variant="flat-info"
                    class="btn-icon"
                    :href="application.details.portfolioUrl"
                    target="_blank"
                >
                  <feather-icon icon="DownloadCloudIcon"/>
                </b-button>
              </div>
            </div>
          </b-col>

          <b-col md="6" xl="4">
            <div class="d-flex align-items-center border mr-lg-5 p-3">
              <i class="fa-regular fa-file fa-2x"></i>

              <div class="ml-2">
                <h6 class="mb-0">Cover Letter</h6>
              </div>

              <div v-if="application.details.coverLetterUrl" class="ml-auto">
                <b-button
                    variant="flat-info"
                    class="btn-icon"
                    :href="application.details.coverLetterUrl"
                    target="_blank"
                >
                  <feather-icon icon="DownloadCloudIcon"/>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="application.moreInformationItems && application.moreInformationItems.length" class="pt-2">
        <hr class="mb-3">

        <h4 class="mb-2">Additional Information</h4>

        <div v-for="infoItem in application.moreInformationItems" :key="infoItem.id" class="row mb-4 pt-2">
          <div class="col-md-8">
            <b-card class="p-2" style="background: #fafafa">
              <div class="d-flex">
                <h6 class="mb-4">Request: {{ infoItem.request }}</h6>
                <span class="text-primary ml-auto">{{ infoItem.status }}</span>
              </div>

              <b-form-textarea
                  class="form-control mb-5"
                  placeholder="Additional Info"
                  rows="8"
                  v-model="infoItem.response" disabled=""
              >
              </b-form-textarea>

              <div v-if="infoItem.files">
                <hr class="mb-3">
                <h6 class="mb-3">Additional Documents</h6>
                <div v-for="file in infoItem.files" :key="file.id" class="d-flex mb-2">
                  <i class="fa-solid fa-link mr-1"></i>
                  <small>{{ file.url }}</small>
                  <b-button
                      variant="flat-info"
                      class="btn-icon ml-auto p-0"
                      :href="file.url"
                      target="_blank"
                  >
                    <feather-icon icon="DownloadCloudIcon"/>
                  </b-button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>

      <div class="actions d-flex justify-content-end pt-2">
        <b-button
            v-if="allowedAction('Ask for more information')"
            variant="primary"
            class="mr-2"
            @click="$refs['more-info-modal'].show()"
        >
          Need More Information
        </b-button>
        <b-button
            v-if="allowedAction('Ask for HR interview')"
            variant="outline-primary"
            class="mr-2"
            @click="askForHRInterview"
        >
          Ask for HR Interview
        </b-button>
        <b-button
            v-if="allowedAction('Ask for technical Interview')"
            variant="info"
            @click="askForTechInterview"
        >
          Ask for Technical Interview
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormTextarea, BRow, BCol, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  props: ['applicationId'],

  components: {
    BBadge,
    BCard,
    BFormTextarea,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    ToastificationContent
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      application: null,
      moreInfoData: null,
      rejectionReasons: null,
    }
  },

  methods: {
    async loadApplication() {
      try {
        const response = await this.$http.get(`professionals/${this.applicationId}`);
        this.application = response.data;
      } catch (error) {
      }
    },

    async sendMoreInfo() {
      try {
        await this.$http.put(`professionals/${this.applicationId}/askformoreinformation`, {
          request: this.moreInfoData
        });

        this.$refs['more-info-modal'].hide();

        await this.loadApplication();

        this.showToast('Your request has been sent.')
      } catch (error) {
      }
    },

    async askForHRInterview() {
      try {
        await this.$http.put(`professionals/${this.applicationId}/askforhrinterview`);

        await this.loadApplication();

        this.showToast('Your request has been sent.')
      } catch (error) {
      }
    },

    async askForTechInterview() {
      try {
        await this.$http.put(`professionals/${this.applicationId}/askfortechnicalinterview`);

        await this.loadApplication();

        this.showToast('Your request has been sent.')
      } catch (error) {
      }
    },

    async reject() {
      try {
        await this.$http.put(`professionals/${this.applicationId}/reject`, {
          reason: this.rejectionReasons
        });

        this.$refs['rejection-modal'].hide();

        await this.loadApplication();

        this.showToast('Your request has been sent.')
      } catch (error) {
      }
    },

    async approve() {
      try {
        await this.$http.put(`professionals/${this.applicationId}/accept`);

        this.$refs['rejection-modal'].hide();

        await this.loadApplication();

        this.showToast('Your request has been sent.')
      } catch (error) {
      }
    },

    allowedAction(action) {
      return this.application.allowedActions && this.application.allowedActions.includes(action);
    },

    showToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    }
  },

  async created() {
    await this.loadApplication();
  }
}
</script>
